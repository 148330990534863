import Richtext from "~/components/Richtext";
import Btn from "~/components/Btn";
import "./Form.css";
import { useContext, useEffect, useRef, useState } from "react";
// import Plx from 'react-plx';

// External components
import Hotkeys from "react-hot-keys";
import { UmbracoContext } from "../contexts/Umbraco";

const Form = ({ c }) => {
    const [sent, setSent] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [sending, setSending] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        // Console log
        let object = {};
        data.forEach((value, key) => object[key] = value);
        console.log(JSON.stringify(object));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        setSending(true);
        fetch('/forms/temareiser', {
            method: 'POST',
            body: JSON.stringify(object),
            headers: myHeaders,
        }).then(response => response.text()).then(text => {
            console.log(text);
            setSending(false);
            setSent("Takk for din henvendelse");
            if (dev) {
                setStatus(text);
            }
        }).catch(error => {
            setSending(false);
            console.error(error);
            setError(error);
        });
    };
    useEffect(() => {
        if (sent && c.successPage?.url) {
            window.open(c.successPage?.url);
        }
    }, [sent]);

    const [contactByPhone, setContactByPhone] = useState(null);
    const [dev, toggleDev] = useState(false);
    const form = useRef();
    const { data, getMediaPath } = useContext(UmbracoContext);
    return (

        // <Plx parallaxData={[
        //     {
        //         start: '.people-quote',
        //         // startOffset: "0vh",
        //         end: '.people-quote',
        //         endOffset: '150vh',
        //         // duration: 500,
        //         properties: [
        //             {
        //                 startValue: 500,
        //                 endValue: 0,
        //                 property: "translateY",
        //             },
        //         ],
        //     },
        // ]}
        // >
        // </Plx>
        <div className="form-wrapper" id="kontakt-oss">
            <Hotkeys
                keyName="ctrl+shift+d"
                allowRepeat={true}
                onKeyDown={() =>
                    toggleDev(d => !d)
                }
            />
            {c.titleAbove && <div className="form__title"><Richtext>{c.titleAbove}</Richtext></div>}
            {dev &&
                <center>
                    <button style={{ color: "red", width: "max-content" }} onClick={() => { setError(r => !r); setSent(null); }}>Toggle error</button>
                    <button style={{ color: "green", width: "max-content" }} onClick={() => {
                        setError(null);
                        setSent(r => r?.length > 0 ? null : "Takk for din henvendelse");
                    }}>Toggle sent</button>
                    <button
                        style={{ color: "blue", width: "max-content" }}
                        onClick={() => {
                            if (form.current.querySelector("input[name='navn']")?.value?.length > 0) {
                                form.current.reset();
                            } else {
                                form.current.querySelector("input[name='navn']").value = "Jens Jenssen";
                                form.current.querySelector("input[name='telefonnummer']").value = "123 456 78";
                                form.current.querySelector("input[name='epost']").value = "test@test.no";
                                form.current.querySelector("textarea[name='melding']").value = "Hei, jeg lurer på om kontaktskjemaet fungerer!";
                                form.current.querySelector("input[name='kontaktetViaTelefonCheckbox']").checked = "true";
                            }
                        }}
                    >
                        Toggle testdata
                    </button>
                    <button style={{ color: "purple", width: "max-content" }} onClick={() => { setError(null); setSent(null); setSending(s => !s); }}>Toggle sending</button>
                </center>
            }
            {
                sending ?
                    <div className="status-box sending-box">
                        {data?.logo?.url && <div className="sending-img"><img src={getMediaPath(data.logo)} /></div>}
                        <center><div class="dot-collision"></div></center>
                        <div>Sender melding</div>
                    </div>
                    :
                    <>
                        {error || sent ?
                            <div className="status-box">
                                {error &&
                                    <div style={{ color: "red" }}>
                                        <Richtext>{c.errorMessage}</Richtext>
                                        <hr />
                                        <center>
                                            <small>Detaljert feilmelding:</small>
                                            <pre>{JSON.stringify(error, null, 1)}</pre>
                                        </center>
                                    </div>
                                }
                                {sent &&
                                    <>
                                        <div style={{ color: "green" }}>
                                            <center>
                                                {sent}
                                            </center>
                                        </div>
                                        <hr />
                                        {dev && status && <center><pre>{JSON.stringify(status, null, 1)}</pre></center>}
                                    </>
                                }
                            </div> :
                            <div className="form-box">
                                <div
                                    className="form-box__col-1"
                                    style={{
                                        backgroundImage: `url(${c?.bgImage?.url?.split("?")[0]}?width=600&height=400&mode=min)`
                                    }}>
                                    <div className="form-box__col-1__overlay" style={{ backgroundColor: c.bgOverlay }} />
                                    {data?.logo && <img src={getMediaPath(data.logo)} className="form-box__logo" />}
                                    <div className="form-box__col1__content">
                                        {c.richtext && <Richtext>{c.richtext}</Richtext>}
                                    </div>
                                </div>
                                <form className="form" onSubmit={onSubmit} ref={form}>
                                    <label className="form__row">
                                        Navn
                                        <input name="navn" type="text" className="form__row__input" required />
                                    </label>
                                    <label className="form__row">
                                        Telefonnummer
                                        <input name="telefonnummer" type="tel" className="form__row__input" required={contactByPhone} />
                                    </label>
                                    <label className="form__row">
                                        E-post
                                        <input name="epost" type="email" className="form__row__input" required={!contactByPhone} />
                                    </label>
                                    <label className="form__row">
                                        Melding
                                        <textarea name="melding" className="form__row__textarea" rows={5} required></textarea>
                                    </label>
                                    <label className="form__row form__row--row form__row--hover">
                                        <input name="kontaktetViaTelefonCheckbox" type="checkbox" className="form__row__checkbox" value="true" onChange={() => {
                                            setContactByPhone(p => !p);
                                        }} /><div className="form__row__checkbox-ticker" />
                                        Jeg ønsker å bli kontaktet via telefon
                                    </label>
                                    <Btn theme="dark" submit center value="Send inn" />
                                </form>
                            </div>
                        }
                    </>
            }
        </div >
    );
};

export default Form;