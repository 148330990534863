import { useContext, useEffect } from 'react';
import { UmbracoContext } from '~/contexts/Umbraco';
import Page from '~/Page';
import CMDK from "~/components/CMDK";

// External components
import Hotkeys from "react-hot-keys";
import { Route, Routes, useLocation } from 'react-router-dom';

function App() {
  const { rootNode, data, dataError } = useContext(UmbracoContext);

  // useEffect(() => {
  //   if (data?.formBg) document.body.style.setProperty('--form-bg', `url("${data.formBg.url.split("?")[0]}?width=800")`);
  //   if (data?.formBgOverlay) document.body.style.setProperty('--form-overlay', data.formBgOverlay);
  // }, [data]);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {data?.children && <CMDK routes={[data, ...data?.children]} />}
      {(rootNode) && (
        <Hotkeys
          keyName="alt+shift+u"
          allowRepeat={true}
          onKeyDown={() => {
            if (window.location.hostname === "localhost") {
              window
                .open(
                  `${rootNode
                    ? `https://sorsport.no/umbraco#/content/content/edit/${rootNode}`
                    : "https://sorsport.no/umbraco"
                  }`,
                  "_blank"
                )
                .focus();
            } else {
              window
                .open(
                  `${rootNode
                    ? `/umbraco#/content/content/edit/${rootNode}`
                    : "/umbraco"
                  }`,
                  "_blank"
                )
                .focus();
            }
          }
          }
        />
      )}
      <Hotkeys
        keyName="alt+shift+g"
        allowRepeat={true}
        onKeyDown={() =>
          window
            .open(
              `https://github.com/aksellsor/aks_react_sortemareiser`,
              "_blank"
            )
            .focus()
        }
      />
      {!dataError ?
        <>
          {data ?
            <Routes>
              {data?.children?.map(child => <Route path={child.url} element={<Page pageData={child} />} />)}
              <Route path="/" element={<Page pageData={data} />} />
            </Routes>
            :
            <div className="loading">Laster inn...</div>
          }
        </>
        : <pre style={{ color: "red" }}>{JSON.stringify(dataError, null, 1)}</pre>
      }

    </>
  );
}

export default App;
