import "./InfoCards.css";
import Richtext from "~/components/Richtext";
import Img from "~/components/Img";
const Card = ({ c }) => {
    let { title, richText, image } = c;
    return (
        // <div className="infocards__card">
        //     <Img className="infocards__card__img" imgObj={image} width={600} params="&height=600&mode=crop" />
        //     <div className="infocards__card__content">
        //         {title ? <div className="infocards__card__title">{title}</div> : null}
        //         <Richtext className="infocards__card__richtext">{richText}</Richtext>
        //     </div>
        // </div>
        <figure className="infocards__card">
            <Img imgObj={image} width={300} multiplier={1.5} height={600} className="infocards__card__img" />

            <figcaption className="infocards__card__content">
                {title ? <div className="infocards__card__title">{title}</div> : null}
                <Richtext className="infocards__card__richtext">{richText}</Richtext>
            </figcaption>
        </figure>
    );
};

const InfoCards = ({
    cards,
    columns
}) => {
    let items = Array.isArray(cards) ? cards : [cards];
    return (
        <div className={`infocards ${columns ? "infocards--columns" : ""}`}>
            {items?.map(c => <Card key={`infocard-${c.key}`} c={c} params="&height=500" />)}
        </div>
    );
};

export default InfoCards;