import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '~/App.jsx';
import '~/styles/global.css';
import { UmbracoProvider } from "~/contexts/Umbraco";
import { BrowserRouter as Router } from 'react-router-dom';
const rootDiv = document.getElementById('root');
ReactDOM.createRoot(rootDiv).render(
  <UmbracoProvider dataset={rootDiv.dataset}>
    <Router>
      <App />
    </Router>
  </UmbracoProvider>,
);
