
// import "./ImageSlider.css";

import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Keyboard } from "swiper/modules";
import EffectCarousel from "./effect-carousel.esm.js";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import "./MySwiper.css";
import { UmbracoContext } from "../contexts/Umbraco.jsx";
const ImageSlider = ({ c }) => {


    let images = c?.images;
    const swiperParameters = {
        modules: [A11y, Navigation, EffectCarousel, Keyboard],
        slidesPerView: "auto",
        navigation: { enabled: false },
        loop: true,
        breakpoints: { 768: { speed: 200, navigation: { enabled: true } } },
        effect: "carousel",
        centeredSlides: true,
        keyboard: true
    };
    const { getMediaPath } = useContext(UmbracoContext);
    return (
        <div className='swiper-outer-wrapper'>
            <Swiper {...swiperParameters}>
                {images?.length > 0 && [...images, ...images]?.map(img =>
                    <SwiperSlide
                        className="swiper-slide-cf25"
                        key={img.key + "splide-slide"}
                    // className='image-slider__slide'
                    >
                        <img
                            src={getMediaPath(img) + "?width=1280&height=720&mode=crop&quality=65"}
                            alt={img.title}
                            className="swiper-slide-bg-image swiper-slide-bg-image-c61b swiper-carousel-animate-opacity"
                        />
                        {/* <div className="swiper-slide-content swiper-carousel-animate-opacity swiper-slide-content-64d1">
                            <div className="swiper-slide-text swiper-slide-text-a9ae">
                                {img.title}
                            </div>
                        </div> */}
                    </SwiperSlide>
                )}

            </Swiper>
        </div>
    );
};

export default ImageSlider;