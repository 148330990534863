import RoundImageText from "~/components/RoundImageText";
import Sitat from "~/components/Sitat";
import Hr from "~/components/Hr";
import Form from "~/components/Form";
import ImageSlider from "~/components/ImageSlider";
import SitatSlider from "~/components/SitatSlider";
import Richtext from "~/components/Richtext";
import Btn from "~/components/Btn";
import InfoCards from "~/components/InfoCards";
import Plx from "react-plx";

const Comp = ({ c }) => {
    let type = c?.alias?.toLowerCase();
    switch (type) {
        case "roundimagetext":
            return <RoundImageText c={c} />;
        case "sitat":
            return <Plx parallaxData={[
                {
                    start: 0,
                    end: "75vh",
                    properties: [
                        {
                            startValue: 0.3,
                            endValue: 1,
                            property: "scale",
                        },
                    ],
                },
            ]}><Sitat text={c.text} forfatter={c.forfatter} /></Plx>;
        case "spacing":
            return <Hr h={c.space} />;
        case "skjema":
            return <Form c={c} />;
        case "imageslider":
            return <ImageSlider c={c} />;
        case "sitatslider":
            return <SitatSlider c={c} />;
        case "richtext":
            return <div className="richtext-wrapper"><Richtext>{c.text}</Richtext></div>;
        case "button":
            return <center><a href={c.link.url}><Btn>{c.link.label}</Btn></a></center>;
        case "infocards":
            return (
                <>
                    <InfoCards cards={c.cards} columns={c.kolonner} />
                </>
            );
        // default:
        //     return <pre>{JSON.stringify(c, null, 1)}</pre>;
        // case "decor":
        //     return <Decor left={c.left} right={c.right} scale={c.scale} flip={c.flip} />;
    }
    // return <div style={{ opacity: 0.5 }}><pre>{JSON.stringify(c, null, 1)}</pre></div>;
};

const Switcher = ({ components }) => {
    if (!components) return null;
    return (
        <>
            {/* <pre>{JSON.stringify(components, null, 1)}</pre> */}
            {components?.map(c => <Comp c={c} key={c.key + "component"} />)}
        </>
    );
};

export default Switcher;