import { useRef } from "react";
import "./RoundImage.css";

import { useInViewport } from "react-in-viewport";
const useInView = (element) => {
    const { inViewport } = useInViewport(element);
    return inViewport;
};
const RoundImage = ({ src, className = "", imageText }) => {

    const imgTarget = useRef();
    const inViewport = useInView(imgTarget);
    return (
        <div >
            <div className={`round-img ${inViewport ? "round-img--in-view" : ""}`}>
                {src &&
                    <>
                        <div className='round-img_image-mask'>
                            <div ref={imgTarget} className="round-img__target" />
                            <img className={`${className} round-img_image`} src={src + "?width=500&height=500&mode=crop"} />
                        </div>
                    </>
                }
                {imageText && (
                    <>
                        <svg
                            className='curved-text'
                            width='100%'
                            height='100%'
                            viewBox='0 0 100 100'
                        >
                            <path
                                id='curve'
                                fill='transparent'
                                d='M0,48.8a48.8,48.8,0,0,0,97.6,0'
                                vectorEffect='non-scaling-stroke'
                            />
                            <text width='500'>
                                <textPath xlinkHref='#curve' fill='currentColor'>
                                    {imageText}
                                </textPath>
                            </text>
                        </svg>
                        {/* <div className='curved-text-margin-bottom'></div> */}
                    </>
                )}
            </div>
        </div>
    );
};

export default RoundImage;