import { useRef } from "react";
import "./Sitat.css";

import { useInViewport } from "react-in-viewport";
const useInView = (element) => {
    const { inViewport } = useInViewport(element);
    return inViewport;
};

const Sitat = ({ text, forfatter, sm = false, className = "", center = false }) => {

    const iconTarget = useRef();
    const inViewport = useInView(iconTarget);
    if (!text && !forfatter) return null;
    if (sm) className += " sitat-wrapper--sm";
    if (center) className += " sitat-wrapper--center";
    return (
        <div className={`sitat-wrapper ${className}`}>
            <svg ref={iconTarget} className={`sitat__icon ${inViewport ? "sitat__icon--in-view" : ""}`} xmlns="http://www.w3.org/2000/svg" width="221.153" height="176.493" viewBox="0 0 221.153 176.493">
                <path d="M1033.825,2260.558h9.762l75.9-78.1v-98.4h-98.4v98.4h58.233Z" transform="translate(-898.336 -2084.065)" fill="currentColor" />
                <path d="M495.266,2182.461l-45.5,78.1h9.762l75.9-78.1v-98.4h-98.4v98.4Z" transform="translate(-437.032 -2084.065)" fill="currentColor" />
            </svg>
            <figure>
                <blockquote className="sitat__text">
                    {text}
                </blockquote>

                {forfatter &&
                    <figcaption className="sitat__author">
                        - {forfatter}
                    </figcaption>
                }

            </figure>
        </div>
    );
};

export default Sitat;