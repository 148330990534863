import parse from 'html-react-parser';
import "./Richtext.css";
import { useEffect } from 'react';
const Richtext = ({ children, className = "" }) => {
    useEffect(() => {
        document.querySelectorAll(".richtext a .btn").forEach(el => {
            el.classList.remove(".btn");
            el.parentElement.classList.add("btn");
        });
    }, []);
    return (
        <div className={`richtext ${className}`}>{typeof children === "string" ? parse(children) : null}</div>
    );
};

export default Richtext;