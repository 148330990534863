import "./Decor.css";
const Decor = ({ left = "0", top = 0, scale = 1, flip = false }) => {
    return (
        <div className="decor">
            <svg
                // style={{ left: left, top: top, transform: `scale(${scale}) scaleX(${flip ? -1 : 1})` }}
                // className="decor"
                xmlns="http://www.w3.org/2000/svg"
                width="3144.037"
                height="4774.896"
                viewBox="0 0 3144.037 4774.896"
            >
                <g
                    fill="#fff"
                    data-name="Group 1194"
                    opacity="0.25"
                    transform="translate(-3311.808 -1365.472)"
                >
                    <path
                        d="M5689.743 3158.161c-28.821 0-73.212 23.355-99.146 52.161L3423.974 5636.756h208.7l2213.593-2478.6z"
                        data-name="Path 59"
                        transform="translate(31.51 503.612)"
                    ></path>
                    <path
                        d="M5922.284 3158.154c-14.413 0-36.623 11.688-49.6 26.1L3710.575 5636.757H3867.1l2185.6-2478.6z"
                        data-name="Path 60"
                        transform="translate(112.024 503.61)"
                    ></path>
                    <path
                        d="M6278.469 3105.193h-104.327l-.009-.009-2230.926 2546.455h104.342z"
                        data-name="Path 61"
                        transform="translate(177.376 488.729)"
                    ></path>
                    <path
                        d="M3888.855 2003.835C3914.8 2032.639 3959.2 2056 3988 2056h208.709L3572.5 1365.472h-260.692z"
                        data-name="Path 62"
                    ></path>
                    <path
                        d="M5532.812 3479.158c25.952 28.821 70.337 52.181 99.143 52.181h156.531L3838.33 1365.472h-208.687z"
                        data-name="Path 63"
                        transform="translate(89.288)"
                    ></path>
                    <path
                        d="M5821.053 3505.254c12.959 14.4 35.174 26.083 49.586 26.083h130.42l-1950.15-2165.865H3894.39z"
                        data-name="Path 64"
                        transform="translate(163.662)"
                    ></path>
                    <path
                        d="M4223.209 1365.973c-.155-.174-.344-.332-.5-.5h-104.292l2006.5 2228.439h104.337z"
                        data-name="Path 65"
                        transform="translate(226.596)"
                    ></path>
                </g>
            </svg>
        </div>

    );
};

export default Decor;