import Richtext from "./Richtext";
import RoundImage from "./RoundImage";
import "./RoundImageText.css";
import Plx from "react-plx";
import { motion } from "framer-motion";
import { HiArrowLongRight } from "react-icons/hi2/index.esm.js";
import { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { UmbracoContext } from "../contexts/Umbraco";

const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;
const RoundImageText = ({ c, className = "" }) => {
    const { getMediaPath } = useContext(UmbracoContext);
    let image = getMediaPath(c?.image);
    const img = useRef();
    let pos = c.imagePosition || "left";
    if (pos) className += ` round-image-text--${pos}`;
    return (
        <div className="round-image-text-wrapper">
            <div className={`round-image-text ${className}`}>
                <div className="round-image-text__text">
                    <Richtext>{c.richtext}</Richtext>
                    {c.ctaLink &&
                        <Link className="cta-btn" whileTap={{ scale: "0.96" }} to={c.ctaLink.url}
                            onMouseOver={() => {
                                img.current.classList.add("round-image-cta-wrapper--hovering");
                            }}
                            onMouseLeave={() => {
                                img.current.classList.remove("round-image-cta-wrapper--hovering");
                            }}
                        >
                            <span className="cta-btn__label"> {c.ctaLink.label}</span>
                            <HiArrowLongRight size="2em" className="cta-btn__icon" />
                        </Link>
                    }
                </div>
                <div className="round-image-cta-wrapper">
                    <ConditionalWrapper condition={c?.ctaLink?.url?.length > 0} wrapper={children => <Link to={c?.ctaLink?.url} ref={img}>{children}</Link>}>
                        <Plx parallaxData={[
                            {
                                start: "self",
                                end: "self",
                                startOffset: -500,
                                endOffset: 1000,
                                properties: [
                                    {
                                        startValue: 0.7,
                                        endValue: 1,
                                        property: "scale",
                                    },
                                    {
                                        startValue: 0,
                                        endValue: -5,
                                        property: "rotate",
                                    },
                                ],
                            },
                        ]}>
                            <RoundImage src={image} imageText={c.imageText} />
                        </Plx>
                    </ConditionalWrapper>
                </div>
            </div>
        </div>
    );
};
export default RoundImageText;

