import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import { Keyboard, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./SitatSlider.css";
import Sitat from "~/components/Sitat";
const SitatSlider = ({ c }) => {
    let sitater = c.sitater;
    const [items, setItems] = useState(null);
    useEffect(() => {
        if (typeof sitater === "object") {
            setItems([sitater, sitater]);
        } else {
            setItems(sitater);
        }
    }, [sitater]);
    const [hovering, setHovering] = useState(false);
    return (
        <>
            <div className='sitat-slider-wrapper'
                onMouseEnter={() => {
                    setHovering(true);
                }}
                onMouseLeave={() => {
                    setHovering(false);
                }}
            >
                <Swiper
                    keyboard={true}
                    className='sitat-slider'
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    navigation
                    modules={[Keyboard, Pagination, Navigation]}
                    pagination={{ clickable: true }}
                >
                    {items?.map((s, index) =>
                        <SwiperSlide className='sitat-slider__slide' key={s.key + "sitat-slider__slide" + index}>
                            <Sitat center text={s.text} forfatter={s.forfatter} sm />
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </>
    );
};

export default SitatSlider;