import { Link } from "react-router-dom";

export const A = ({ children, className = "", href }, props) => {

    if (href) {
        if (href.startsWith("/")) {
            return (
                <Link to={href} className={` ${className}`} {...props}>
                    {children}
                </Link>
            );
        } else if (href.startsWith("#")) {
            return (
                <a href={href} className={` ${className}`} {...props}>
                    {children}
                </a>
            );
        } else {
            {
                return (
                    <a href={href} className={` ${className}`} {...props} target="_blank" >
                        {children}
                    </a>
                );
            }
        }
    }
};