// import 'lazysizes';

const Img = ({ imgObj, params = "&quality=80", width = 200, step, minWidth = 50, className = "", height = "", lazy = true, multiplier = 1, ...props }) => {
    if (!imgObj) return null;
    let obj = imgObj;
    let url = obj?.url?.replace(/\?.*/g, "");
    function generateResponsiveImageString(url, extraParams) {
        let responsiveString = '';
        if (!step) {
            if (width < 800) {
                step = 100;
            } else {
                step = 200;
            }
        }
        for (let currentWidth = minWidth; currentWidth <= width; currentWidth += step) {
            responsiveString += `${url}?width=${currentWidth * multiplier}${extraParams} ${currentWidth}w,\n`;
        }

        return responsiveString;
    }
    return (
        <>
            {/* {`${url}?width=${maxWidth}${params}`} */}
            {/* <pre className='container'>{JSON.stringify(obj, null, 1)}</pre> */}
            <picture
            // style={{ background: `url("${url}?width=10&quality=5") center center / cover no-repeat`, aspectRatio: `${imgObj.width} / ${imgObj.height}`, display: "grid", width: width }}
            >
                <source
                    type="image/webp"
                    srcset={generateResponsiveImageString(url, `&format=webp${params}`)}
                />
                <img
                    width={width}
                    // style={{ opacity: 0, transition: "all 1s ease-in-out 3s" }}
                    // onLoad={e => e.target.style.opacity = 1}
                    loading={lazy ? "lazy" : "eager"}
                    alt={imgObj.title}
                    srcset={generateResponsiveImageString(url, `${params}`)}
                    src={`${url}?width=${width}${params}`}
                    // sizes="(min-width: 1000px) 930px, 90vw"
                    className={`${className} `}
                    {...props}
                />
            </picture>
            {/* <img
                data-sizes="auto"
                data-src={url + params}
                data-srcset="image1.jpg 300w,
    image2.jpg 600w,
    image3.jpg 900w"
                {...props}
            /> */}
        </>
    );
};

export default Img;