import React, { useContext, useEffect } from 'react';
import "~/styles/Page.css";
import Hr from '~/components/Hr';
import Btn from '~/components/Btn';
import Richtext from '~/components/Richtext';
import Arrow from '~/components/Arrow';
import Switcher from '~/components/Switcher';
import Decor from '~/components/Decor';
import { motion } from "framer-motion";
import Plx from "react-plx";
import { Link } from 'react-router-dom';
import { HiArrowLongLeft } from "react-icons/hi2/index.esm.js";
import { UmbracoContext } from './contexts/Umbraco';
import parse from 'html-react-parser';
import { A } from './components/A';
// import useWindowDimensions from './hooks/useWindowDimensions';

const Page = ({ pageData }) => {
    let socialMediaLinks = Array.isArray(pageData.socialMediaLinks) ? pageData.socialMediaLinks : [pageData.socialMediaLinks];
    // const { height, width } = useWindowDimensions();
    const { setCurrentRoute, data, getMediaPath } = useContext(UmbracoContext);
    useEffect(() => {
        setCurrentRoute(pageData);
    }, [pageData]);

    const half = Math.ceil(data.footerLogoItems?.length / 2);
    let leftFooterLogos = data.footerLogoItems?.slice(0, half);
    let rightFooterLogos = data.footerLogoItems?.slice(half);
    let components = Array.isArray(pageData?.components) ? pageData?.components : [pageData?.components];
    let hideScrollDownArrow = pageData?.hideScrollDownArrow;
    return (
        <div className={`page page--${pageData.alias}`}>
            {pageData.alias === "temareise" && <Link to="/" className='back-btn'>
                <HiArrowLongLeft size="2em" /> <span>Tilbake</span></Link>}
            <Decor />
            <Plx className="page__header"
                parallaxData={[
                    {
                        start: 0,
                        end: "100vh",
                        properties: [
                            {
                                startValue: 0,
                                endValue: 100,
                                property: "translateY",
                            },
                        ],
                    },
                ]}
                style={{ "--overlay": `${pageData?.headerOverlay || "rgba(156, 26, 76, 0.5)"}` }}
            >
                <Plx className='page__header__bg-img'
                    style={{ backgroundImage: `url(${pageData?.headerBilde?.url.split("?")[0]}?width=2560)` }}
                    parallaxData={[
                        {
                            start: 0,
                            end: "100vh",
                            properties: [
                                {
                                    startValue: 1,
                                    endValue: 1.5,
                                    property: "scale",
                                },
                            ],
                        },
                    ]} />
                <div className="page__header__inner">
                    <Plx parallaxData={[
                        {
                            start: 0,
                            end: 500,
                            properties: [
                                {
                                    startValue: 0,
                                    endValue: -200,
                                    property: "translateY",
                                },
                            ],
                        },
                    ]}>
                        <A href="/">
                            <motion.img
                                initial={{
                                    opacity: 0,
                                    y: -20,
                                    scale: 0.8
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    scale: 1,
                                    transition: {
                                        duration: 1
                                    }
                                }}
                                transition={{
                                    duration: 0.2
                                }}
                                whileHover={{
                                    scale: 1.05,
                                    opacity: 0.8
                                }}
                                whileTap={{
                                    scale: 1.1,
                                    opacity: 0.5
                                }}
                                src={getMediaPath(data.logo)} className='page__header__logo' />
                        </A>
                    </Plx>

                    <Plx parallaxData={[
                        {
                            start: 0,
                            end: 150,
                            properties: [
                                {
                                    startValue: 1,
                                    endValue: 0.5,
                                    property: "scale",
                                },
                                {
                                    startValue: 1,
                                    endValue: 0,
                                    property: "opacity",
                                },
                            ],
                        },
                    ]}>
                        {parse(`<h1 className='page__title'>${pageData.title.replace(/--/g, "&shy;")}</h1>`)}
                    </Plx>
                    <Plx style={{ transformOrigin: "top center" }} parallaxData={[
                        {
                            start: 150,
                            end: "150vh",
                            properties: [
                                {
                                    startValue: 1,
                                    endValue: 30,
                                    property: "scaleY",
                                },
                            ],
                        },
                    ]}>
                        <Plx className='page__hr' parallaxData={[
                            {
                                start: 0,
                                end: 150,
                                properties: [
                                    {
                                        startValue: 1,
                                        endValue: 0.2,
                                        property: "scaleX",
                                    },
                                    {
                                        startValue: 0,
                                        endValue: 90,
                                        property: "rotate",
                                    }
                                ],
                            },
                        ]}><Hr h={100} border /></Plx>
                    </Plx>
                    {pageData.headerCta &&
                        <Btn
                            center
                            className='page__btn'
                            onClick={() => {
                                let url = pageData.headerCta?.url;
                                if (url?.startsWith("#")) {
                                    let el = document.querySelector(url);
                                    if (el) {
                                        el.scrollIntoView();
                                        el.querySelector("input")?.focus();
                                    }
                                } else {
                                    if (url?.startsWith("/")) {
                                        window.location.href = window.location.origin + url;
                                    } else {
                                        // window.open(url);
                                    }
                                }
                            }}>
                            {pageData.headerCta?.label}
                        </Btn>
                    }

                    {components?.length > 0 && !hideScrollDownArrow && <div className='page__header__arrow'><Plx parallaxData={[
                        {
                            start: "0vh",
                            end: "100vh",
                            properties: [
                                {
                                    startValue: -50,
                                    endValue: 800,
                                    property: "translateY",
                                },
                            ],
                        },
                    ]}><Arrow /></Plx></div>}
                </div>
            </Plx>

            {components?.length > 0 &&
                <main className='page__content'>
                    <Switcher components={components} />
                </main>
            }

            <footer className='page__footer'>

                <div className='page__footer__container'>
                    {leftFooterLogos?.map(l => l.link && <A href={l.link.url} title={l.link.label}><img src={getMediaPath(l.img)} className='page__footer__container__logo' /></A>)}
                    <Richtext>{data.footerText}</Richtext>
                    {rightFooterLogos?.map(l => l.link && <A href={l.link.url} title={l.link.label}><img src={getMediaPath(l.img)} className='page__footer__container__logo' /></A>)}
                    <div>  {socialMediaLinks?.map(item => item?.img && <A href={item?.url} title={item?.label}><img src={getMediaPath(item.img)} /></A>)}</div>

                </div>
            </footer>
        </div>
    );
};

export default Page;